$breakpoint-landscape: 480px;

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: auto;

  @media (min-width: $breakpoint-landscape) {
    overflow: hidden;
  }
}

#root > div {
  height: 100%;
}

.logo-image {
  width: 128px;
  height: auto;
}

.close-button {
  color: black;
  position: relative;
  line-height: 1 !important;
  width: 14px !important;
  height: 14px !important;
  min-width: auto !important;
  min-height: auto !important;
}

.modal-wrapper {
  .Modal-header {
    padding-bottom: 0px !important;
    padding: 0 !important;
  }

  .Modal-body {
    padding: 0 !important;
  }

  .Modal-content {
    width: calc(100% - 32px);
    max-width: 500px;
    min-width: auto;
    border-radius: 5px;
    height: calc(100% - 32px);
    max-height: none !important;
  }
}

.modal-wrapper--nohead {
  .Modal-header {
    padding-bottom: 0px !important;
    padding: 0 !important;
  }

  .Modal-body {
    padding: 0 !important;
    overflow: hidden !important;
  }

  .Modal-content {
    border-radius: 5px;
    width: calc(100% - 32px);
    max-width: 500px;
    min-width: auto;
  }

  .scrollable-content-container {
    max-height: 65vh;
    overflow-y: auto;
  }
}

input[type='text']:disabled,
input[type='email']:disabled,
input[type='number']:disabled,
select:disabled {
  color: var(--sui-colors-gray-9) !important;
  opacity: 1 !important;
}


.cart-button {
  min-width: auto !important;
}

.select-container .FieldGroup-message--error.sui-caption {
  height: 20px;
}


.location-autocomplete-dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #e3e3e3;
  max-height: 300px;
  overflow: auto;
  z-index: 99;
  border-radius: 0 0 4px 4px;

  button {
    display: block;
    border: none;
    background-color: white;
    background-size: contain;
    height: auto;
    text-align: start;
    width: 100%;
    line-height: 1.2;
    padding: 8px 8px 8px 40px;
    position: relative;
    margin: 0 !important;
    white-space: normal;
    font-weight: normal;
    cursor: pointer;
    &:hover {
      background-color: #e3e3e3;
    }
    .Icon {
      position: absolute;
      background: none;
      color: rgb(143, 148, 159);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: 3px;
      left: 10px;
      padding: 4px;
    }

    b, span {
      display: block;
    }
  }
}

.Input {
  font-size: 1rem;
}
